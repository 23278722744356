.login-container {
    background-color: #2c3e50;
    padding: 20px;
    border-radius: 10px;
    animation-name: flyIn-bottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

#login-title {
    color: white;
    font-size: 50px;
    animation-name: flyIn-top;
      animation-duration: 0.5s;
      animation-timing-function: ease-out;
}

.overlay {
  background-color: white;
  opacity: 0.95;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.reload-container {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}

@media (max-width: 576px) {
    #login-title {
        font-size: 24px;
    }

}