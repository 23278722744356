:root {
	font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  }
*,body,html{
	margin: 0;
	padding: 0;
}
body{
	padding-bottom: constant(safe-area-inset-bottom);
	padding-bottom: env(safe-area-inset-bottom);
}
html::-webkit-scrollbar {
	display: none;
}
