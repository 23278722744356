.config{
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    &_form{
        padding: 20px;
        border-radius: 20px;
        background-color: #fbfbfb;
        h3{
            color: #333;
        }
		p{
			margin-top: 4px;
			color: #999;
			font-size: 14px;
		}
    }
}
