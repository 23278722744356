.openAiLogo{
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

@keyframes logo {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-20px); }
        100% { transform: translateY(0); }
    }
.logo {
    animation: logo 1.5s infinite;
}