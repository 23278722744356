.jsonPre {
	padding: 5px;
	margin: 5px;
	white-space: pre-wrap;

	[class*='string'] {
		color: green;
	}

	[class*='number'] {
		color: darkorange;
	}

	[class*='boolean'] {
		color: blue;
	}

	[class*='null'] {
		color: magenta;
	}

	[class*='key'] {
		color: red;
	}
}
